$font-family-sans-serif: 'Poppins', sans-serif;
$font-weight-normal: 300;
$font-weight-bold: 500;
$headings-font-weight: 400;
$white: #fff;

$body: #fff;
$body-bg: #e5e5e5;
$body-color: #fff;

$primary: $white;
$secondary: #f3f6f9;
$dark: #5d6473;
$muted: #b5b5c3;
$light: #f3f6f9;
$success: #28a745;

$orange: #ffa800;
$light-orange: #fff4de30;
$green: #1bc5bd;
$light-green: #c9f7f530;
$purple: #8950fc;
$light-purple: #eee5ff30;
$red: #f64e60;
$light-red: #ffe2e630;
$light-primary: #e1f0ff30;
$disabled: #333;

$card-bg: #0c1f33;
$text-muted: $muted;
$brand-color: $white;
$logo-height: 1.5rem;
$bg-image:  #07182b;
$footer-color: $white;

$lead-font-size: 1.15rem;
$h6-font-size: 1.125rem;

// STATCARD
$statcard-title-size: 0.75rem;
$statcard-value-size: 1.5rem;
$statcard-subvalue-size: 0.6rem;

// BORDER
$border-radius: 1rem;
$border-color: darken($dark, 40%);

// INPUT
$input-border-radius: 0.2rem;
$input-color: $secondary;
$input-bg: $card-bg;
$input-disabled-bg: $disabled;
$input-hover-border-color: darken($border-color, 15%);
$label-margin-bottom: 0.25rem;
$form-text-margin-top: 0.25rem;

// TABLE
$table-color: $white;
$table-cell-padding: 1.5625rem;
$table-font-size: 0.875rem;
$table-head-font-size: $table-font-size;
$table-border-color: rgba(255, 255, 255, 0.3);

// CARD
$card-border-color: rgba(0, 0, 0, 0.4);
$card-cap-bg: none;
$card-border-radius:  2rem;

// MODAL
$modal-content-bg: rgba(25, 24, 63, 0.98) none repeat scroll 0% 0%;
$modal-content-border-width: 1;
$modal-content-border-radius: $border-radius;
$modal-content-box-shadow-xs: rgba(255, 255, 255, 0.2) 0 0 0 0.5px inset;

// BUTTONS
$btn-padding-y: 0.5rem;
$btn-padding-x: 1.3125rem;
$btn-padding-y-sm: 0.3rem;
$btn-padding-x-sm: 1.1rem;
$btn-font-size: 0.875rem;
$btn-font-size-sm: 0.6875rem;
$btn-line-height: 1.125rem;
$btn-line-height-sm: 1.0625rem;
$btn-border-radius: 0.375rem;
$btn-border-radius-sm: $btn-border-radius;

// BADGES
$badge-font-weight: 300;
$badge-border-radius: 0.375rem;
$badge-padding-y: 0.375rem;
$badge-padding-x: 0.75rem;
$badge-font-size: 0.6875rem;

// DROPDOWN
$dropdown-color: $primary;
$dropdown-bg: rgba(0, 0, 0, 0.9);
$dropdown-box-shadow: rgba(255, 255, 255, 0.2) 0 0 0 0.5px inset;
$dropdown-link-disabled-color: $disabled;
$dropdown-link-color: $primary;

$link-hover-color: darken($primary, 7%);
.btn-primary {
  background: linear-gradient(188deg, #29eff0 10%, #4a98b5 70%) !important;
}
#closeButton {
  background: #dc3545 !important;
}
$theme-colors: (
  'orange': $orange,
  'light-orange': $light-orange,
  'green': $green,
  'light-green': $light-green,
  'purple': $purple,
  'light-purple': $light-purple,
  'red': $red,
  'light-red': $light-red,
  'light-primary': $light-primary,
  'white': $white,
);

@import './main';
