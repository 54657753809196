.statcard {
  min-height: 8.8rem;
  min-width: 10rem;
  flex-basis: 0;
  margin: 5px;
  flex-grow: 1;
  font-size: 0.85rem;
  color: black !important;
  @include media-breakpoint-only(lg) {
    min-width: 13rem;
  }
  .icon {
    height: 2rem;

    svg {
      height: 2rem;
      width: auto;
    }
  }
}
