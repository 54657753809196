.navbar {
  svg.logo {
    fill: $brand-color;
    height: 5rem;
    width: auto;
  }
  .navbar-brand {
    color: $brand-color;
    line-height: $logo-height;
  }
}
